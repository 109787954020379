import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import 'babel-polyfill'		// Required for "Fetch as Google" to work in Google Webmaster Tools Search Console
import { unregister } from './registerServiceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

unregister()
