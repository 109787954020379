import React, { Component } from 'react';
import PropTypes from 'prop-types';

class GameScore extends Component {
	render() {
		return (
			<div className="game-score">
				<TeamScore
					team={this.props.hteam}
					score={this.props.hscore}
					isWinner={this.props.winner ? this.props.winner === this.props.hteam.id : false}
				/>
				<TeamScore
					team={this.props.ateam}
					score={this.props.ascore}
					isWinner={this.props.winner ? this.props.winner === this.props.ateam.id : false}
				/>
			</div>
		);
	}
}

GameScore.propTypes = {
	hteam: PropTypes.object.isRequired,
	ateam: PropTypes.object.isRequired,
	hscore: PropTypes.number,
	ascore: PropTypes.number,
	winner: PropTypes.number,
};

class TeamScore extends Component {

	render() {
		if (this.props.team === undefined) {
			return null;
		}

		const cName = this.props.isWinner === undefined ? '' : 'last-tip-' + (this.props.isWinner ? 'winner' : 'loser');

		return (
			<div className={"last-tip-teambox " + cName}>
				<img src={this.props.team.logo} className="tinylogo" alt="" />
				<span className="last-tip-teamname">
					{this.props.team.abbrev}
				</span>
				<span className="last-tip-teamscore">
					{this.props.score}
				</span>
			</div>
		);
	}
}

TeamScore.propTypes = {
	isWinner: PropTypes.bool,
	team: PropTypes.object.isRequired,
	score: PropTypes.number,
};

export default GameScore;

