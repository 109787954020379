import React from 'react'
import PropTypes from 'prop-types'

export default function TeamLogo(props) {
	const h = props.home.substr(0, 1)
	return (
		<div className={"logo-box logo-box-" + props.home} >
			<img id={h + "teamimg"} src={props.team.logo} alt={props.team.name} />
		</div>
	)
}

TeamLogo.propTypes = {
	team: PropTypes.object,
	home: PropTypes.string,
}
