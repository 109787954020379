import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import TeamLogo from './TeamLogo';

import NumericInput from 'react-numeric-input';

class ManualEntry extends Component {

	constructor(props) {
		super(props);
		this.state = {
			lastGameId: undefined
		};

		this.enterManualScore = this.enterManualScore.bind(this);
		this.updateHScore = this.updateHScore.bind(this);
		this.updateAScore = this.updateAScore.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (!nextProps.showManual) {
			return null;
		}

		if (nextProps.game && nextProps.game.id === prevState.lastGameId) {
			return null;
		}

		if (nextProps.tip) {
			return {
				hPts: nextProps.tip.hPts,
				aPts: nextProps.tip.aPts,
				lastGameId: nextProps.game.id,
			}
		} else {
			return {
				hPts: 85,
				aPts: 85,
				lastGameId: nextProps.game.id,
			};
		}
	}

	updateHScore(n) { this.setState({ hPts: this.minMaxScore(n) }); }
	updateAScore(n) { this.setState({ aPts: this.minMaxScore(n) }); }

	minMaxScore(n) {
		n = Number.parseInt(n, 10);
		if (n < 1) { return 1; }
		if (n > 239) { return 239; }
		return n;
	}

	enterManualScore(event) {
		this.props.uManual({
			e: event,
			game: this.props.game,
			hPts: this.state.hPts,
			aPts: this.state.aPts,
		});
		this.props.toggleShowManual();
	}

	render() {
		if (!this.props.game) {

			return null;
		} else {
			return (
				<ReactModal
					isOpen={this.props.showManual}
					overlayClassName="ModalOverlay"
					className="ModalContent ModalContentSmall"
					contentLabel="Manual"
					shouldCloseOnOverlayClick={true}
					shouldCloseOnEsc={true}
					onRequestClose={this.props.toggleShowManual}
				>
					<section id="manualentry-modal">
						<div id="manualentry-game">
							<TeamLogo team={this.props.game.hteam} home="home" />
							<TeamLogo team={this.props.game.ateam} home="away" />
							<NumericInput min={1} max={239} value={this.state.hPts} className="manualentry-homescore" onChange={this.updateHScore} />
							<NumericInput min={1} max={239} value={this.state.aPts} className="manualentry-awayscore" onChange={this.updateAScore} />
							<p>
								<button
									className="sbox command"
									onClick={this.enterManualScore}
								>
									Enter
								</button>
							</p>
						</div>
					</section>
				</ReactModal>
			);
		}
	}
}

ManualEntry.propTypes = {
	game: PropTypes.object,
	tip: PropTypes.object,
	toggleShowManual: PropTypes.func.isRequired,
	showManual: PropTypes.bool,
	uManual: PropTypes.func.isRequired,
};

export default ManualEntry;
