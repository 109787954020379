import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Progress } from 'react-sweet-progress';
import { computeLadder } from './Ladder';

//
// Tip by randomly varying the expected outcome for each match.
//
export const aiVary = function(hconf) {
	const expectedPts = (130 * hconf) - 65;
	const stdDev = 37.1;	// From The Arc; other people say 33-40
	const r = gaussRandom();
	const pts = expectedPts + (r * stdDev);
	return {
		pts: pts,
		r: r,
	};

	function gaussRandom() {
		return Math.sqrt(-2.0 * Math.log(Math.random())) * Math.cos(2.0 * Math.PI * Math.random())
	}
}

export const ptsToScores = function(iPts, allowDraws) {

	// Force a result
	if (iPts === 0 && !allowDraws) {
		iPts = 1;
	}

	//
	// "Neutral" score is randomly somewhere between 75 and 91.
	//
	const base = 75 + (Math.floor(Math.random() * 16));

	//
	// 60% of the margin is allocated to the higher-scoring team.
	//
	const mult = (iPts > 0 ? 0.6 : 0.4);

	const hPts = Math.round(mult * iPts);
	const aPts = hPts - iPts;

	return {
		hPts: base + hPts,
		aPts: base + aPts,
	}
}

export const tipToRel = function(tip) {
	if (tip && tip.hPts && tip.aPts) {
		const margin = tip.hPts - tip.aPts;
		return 50 - (100 * (margin / 2) / 65);
	}
	return undefined;
}


//
// Sims method:
//
// 1. Calculate 100 or so ladders using the aiVary method.
// 2. Calculate the mean number of wins for each team
// 3. Score each ladder based on how close it is to the mean
// 4. Display the most common ladder
//
export const doAutoTip = function() {

	const DELAY = 10;
	const step = 100;
	const numSims = 5000;

	//
	// Run a batch of simulations, e.g. sims #1 - #50
	//
	function runSims(me, sims, start, step) {
		return new Promise(resolve => setTimeout(
			() => {
				for (let i = start; i < start + step; i++) {
					sims[i] = runSim(me);
				}
				resolve(sims);
			},
			DELAY));
	}

	//
	// Run a single simulation, returning a 'tips' object for one entire season.
	//
	function runSim(me) {
		const tips = Object.assign( {}, me.state.tips);

		for (let id of me.state.fixture) {
			if (!tips[id]) {
				const g = me.state.games[id];
				const aiHConf = me.state.ai[id] ? me.state.ai[id].hconfidence / 100 : 0.50;
				const aiTip = aiVary(aiHConf);
				const pts = aiTip.pts;
				const r = aiTip.r;
				const iPts = Math.round(pts);
				const scores = ptsToScores(iPts);
				const winner = pts >= 0 ? g.hteamid : g.ateamid;
				const t = {
					gameId: id,
					winner: winner,
					hPts: scores.hPts,
					aPts: scores.aPts,
					aiTip: true,
					r: r,
				};
				tips[id] = t;
			}
		}
		return tips;
	}

	//
	// Add up the ranks of teams in a batch of sims
	//
	function calculateTLadders(me, tLadders, sims, start, step) {
		return new Promise(resolve => setTimeout(
			() => {
				for (let i = start; i < start + step; i++) {
					const data = calculateTLadder(me, sims[i]);
					tLadders[i] = { };
					for (let t of data) {
						tLadders[i][t.id] = {
							pts: t.pts,
							perc: t.perc,
							rank: t.rank,
						}
					}
				}
				resolve(tLadders);
			},
			DELAY));
	}

	//
	// Calculate the ranks of teams in a particular sim
	//
	function calculateTLadder(me, sim) {
		const data = computeLadder(me.state.teams, me.state.games, me.state.results, sim);
		return data.ladderTeams.slice();
	}

	//
	// Calculate the 'normality' scores of a batch of sims.
	//
	function scoreSims(me, simScores, tLadders, avgLadder, sims, start, step) {
		return new Promise(resolve => setTimeout(
			() => {
				for (let i = start; i < start + step; i++) {
					simScores[i] = scoreSim(me, tLadders[i], avgLadder, sims[i]);
				}
				resolve(simScores);
			},
			DELAY));
	}

	//
	// Calculate the 'normality' score of a single sim.
	//
	function scoreSim(me, ladder, avgLadder, sim) {

		//
		// Add up divergence of all teams in this sim
		//
		let score = 0;
		for (let teamId in ladder) {
			const ptsDivergence = Math.abs(ladder[teamId].pts - avgLadder[teamId].pts);
			const rankDivergence = Math.abs(ladder[teamId].rank - avgLadder[teamId].rank);
			const percDivergence = Math.abs(ladder[teamId].perc - avgLadder[teamId].perc);

			//
			// Weightings: How important is a divergence of 1 rank vs 1 premiership point
			//             vs 1 percentage point?
			//
			score += (6 * rankDivergence) + ptsDivergence + (0.5 * percDivergence);
		}

		//
		// Consider the outrageousness of tips
		//
		let outrageousness = 0;
		for (let tip of Object.values(sim)) {
			const tipOutrageousness = Math.pow(Math.abs(tip.r), 1.5);
			outrageousness += tipOutrageousness;
		}

		//
		// Convert to average, so we're not distorted by number of games remaining
		//
		outrageousness /= Object.keys(sim).length;

		//
		// Scale to roughly balance priority with 'score'
		//
		outrageousness *= 80;

		// console.log("Sim outrageousness:", i, outrageousness, score, score + outrageousness);

		score += outrageousness;

		return score;
	}

	async function process(me) {

		//
		// 'results' is an array with each element containing an object of tips,
		// e.g. results[0] = { 450: { gameId: 450, winner: 7, ... }, ... }
		//
		let sims = Array(numSims);

		//
		// 'tLadders' is an object with each element holding the ladder as a result of that sim
		//
		let tLadders = { };

		//
		// Set initial state
		//
		me.setState({
			isAutoTipping: true,
			autoTipSims: numSims,
			autoTipProgress: 0,
		});

		//
		// Run sims
		//
		for (let i = 0; i < numSims; i += step) {
			sims = await runSims(me, sims, i, step);

			me.setState({
				autoTipProgress: (i / numSims) * 40,
			});
		}

		//
		// Calculate ladders
		//
		for (let i = 0; i < numSims; i += step) {
			tLadders = await calculateTLadders(me, tLadders, sims, i, step);

			me.setState({
				autoTipProgress: 40 + (i / numSims) * 40,
			});
		}

		// console.log('tLadders', tLadders);

		//
		// Setup & calculate average ladder
		//
		const avgLadder = { };
		for (let teamId in me.state.teams) {
			avgLadder[teamId] = {
				pts: 0,
				rank: 0,
				perc: 0,
				name: me.state.teams[teamId].name,		// Not needed except for debugging
			};
		}

		for (let i = 0; i < numSims; i++) {
			Object.keys(tLadders[i]).map((teamId) => {
				avgLadder[teamId].pts += tLadders[i][teamId].pts;
				avgLadder[teamId].rank += tLadders[i][teamId].rank;
				avgLadder[teamId].perc += tLadders[i][teamId].perc;
				return null;
			});
		}

		me.setState({
			autoTipProgress: 81,
		});

		//
		// Convert tWins from being a total number of wins for each team in all sims
		// to the average number of wins per team across all sims.
		//
		for (let id in avgLadder) {
			avgLadder[id].pts /= numSims;
			avgLadder[id].rank /= numSims;
			avgLadder[id].perc /= numSims;
		}

		// console.log('avgladder', avgLadder);

		//
		// Prepare to score each sim based on how close to the average it is.
		//
		const simScores = Array(numSims);

		for (let i = 0; i < numSims; i += step) {
			await scoreSims(me, simScores, tLadders, avgLadder, sims, i, step);
			me.setState({
				autoTipProgress: 81 + ((i / numSims) * 19),
			});
		}

		let minError;
		let bestSim;
		for (let i = 0; i < numSims; i++) {
			if (bestSim === undefined || simScores[i] < minError) {
				bestSim = i;
				minError = simScores[i];
			}
		}

		// console.log("best", bestSim, minError, sims[bestSim]);

		me.setState({
			autoTipProgress: 100,
		});

		// slightly delay this so the app isn't overloaded at the point it needs
		// to re-render the ladder.
		setTimeout(() => {
			me.setState({
				isAutoTipping: false,
				tips: sims[bestSim],
				lastClick: new Date(),	// trigger nextGame
			});
		}, 500);
	}

	if (!this.state.isAutoTipping) {
		process(this);
	}
}

class AutoTip extends Component {

	constructor(props) {
		super(props);
		this.state = {
		};
	}

	static getDerivedStateFromProps(nextProps) {
		if (nextProps.autoTipProgress !== 0) {
			return null;
		}

		const jokeStage = [
			'Correcting spelling of cheer squad banners',
			'Trading out forwards',
			'Drafting 18-year-old with knee troubles',
			'Adjusting for umpire bias',
			'Stretching hamstrings',
			'Conducting school clinics',
			'Signing autographs',
			'Arranging under-the-table payments',
			'Downscaling to standard definition',
			'Painting advertisements in goal square',
			'Placating sponsors',
			'Thanking members',
			'Verifying full support of board',
			'Playing kick-to-kick',
			'Determining rule interpretation of the week',
			'Inflating optimism',
			'Booking September holidays',
			'Suspending troublemakers',
			'Recording first strikes',
			'Sacking coaches',
			'Poaching assistant coaches',
			'Scalping Grand Final tickets',
			'Waiting on video score reviews',
			'Bemoaning state of the game',
			'Auctioning media rights',
			'Tallying Brownlow votes',
		];

		const stages = [
			'Preparing to run simulations',
			'Simulating ' + nextProps.autoTipSims + ' seasons',
			'Simulating ' + nextProps.autoTipSims + ' seasons',
			'Applying Gaussian randomness',
			'Scoring sims for normality',
			jokeStage[Math.floor(Math.random() * jokeStage.length)],
			'Searching for most representative sim',
		];
		return {
			stages: stages,
		};
	}

	render() {

		if (!this.props.isAutoTipping) {
			return null;
		}

		const progress = Number(this.props.autoTipProgress).toFixed(1);

		const stageText = this.state.stages[Math.floor(this.props.autoTipProgress / 100 * this.state.stages.length)];

		return (
			<ReactModal
				isOpen={this.props.isAutoTipping}
				overlayClassName="ModalOverlay"
				className="ModalContent ModalContentSmall"
				contentLabel="AutoTipping"
				shouldCloseOnOverlayClick={false}
				shouldCloseOnEsc={false}
			>
				<h2 id="autotip-header">
					AutoTipping
				</h2>
				<p>
					{stageText}...
				</p>
				<Progress
					percent={progress}
					type="circle"
				/>
			</ReactModal>
		);
	}
}

AutoTip.propTypes = {
	isAutoTipping: PropTypes.bool,
	autoTipSims: PropTypes.number,
	autoTipProgress: PropTypes.number,
};


export default AutoTip;
