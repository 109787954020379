import React, { Component } from 'react';
import ReactModal from 'react-modal';
import ModalCloseBox from './ModalCloseBox';
import pkgInfo from '../package.json';

class HelpText extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsOpen: false,
		};
		this.onClick = this.onClick.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	onClick() {
		this.setState({
			modalIsOpen: true,
		});
	}

	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
	}

	render() {
		const { version } = pkgInfo

		return (
			<div id="helptext">
				<div
					className="App-helpline"
					onClick={this.onClick}
				>
					<button
						className="sbox command"
					>
						How to Use
					</button>
				</div>
				<ReactModal
					id="help-content"
					isOpen={this.state.modalIsOpen}
					overlayClassName="ModalOverlay ModalOverlaySmall"
					className="ModalContent"
					contentLabel="HelpText"
					shouldCloseOnOverlayClick={true}
					shouldCloseOnEsc={true}
					onRequestClose={this.closeModal}
				>
					<ModalCloseBox closeModal={this.closeModal} />
					<h2>
						Using The Ladder Predictor
					</h2>
					<section className="help-section">
						<header>
							<p>
								To Manually Tip The Whole Season
							</p>
						</header>
						<p>
							Tap to tip a winner for each game. The further from the
							middle you tap, the greater the winning margin.
						</p>
					</section>
					<section className="help-section">
						<header>
							<p>
								To Tip Some Games
							</p>
						</header>
						<p>
							Tap <b>AUTOTIP</b> at any time to engage computer modelling
							for remaining games.
						</p>
					</section>
					<section className="help-section">
						<header>
							<p>To View Tips or the Fixture</p>
						</header>
						<p>
							Tap the <b>FIXTURE</b> box below the tip area on the right.
						</p>
					</section>
					<section className="help-section">
						<header>
							<p>To Tip One Team&apos;s Games</p>
						</header>
						<p>
							View the fixture by tapping the <b>FIXTURE</b> box, then
							tap a team logo to filter games. Return to the main screen
							and continue tipping. When you reach the end of the filtered team&apos;s
							games, tap <b>AUTOTIP</b> to use computer modelling for other teams&apos;
							games.
						</p>
					</section>
					<section className="help-section">
						<header>
							<p>To Share Your Ladder</p>
						</header>
						<p>
							Tap the <b>COPY</b> button at the bottom of the screen to copy your
							ladder. You can then paste it somewhere else.
						</p>
					</section>
					<section className="help-section">
						<header>
							<p>
								To Track a Fantasy League
								<span className="help-new">
									v1.8.0
								</span>
							</p>
						</header>
						<p>
							To replace real match results with your own, tap <b>FIXTURE</b> and tick <b>Allow tipping of past games</b>.
							You can choose which year to use all the way back to 1897, but finals are currently only available from the year 2000.
						</p>
					</section>
					<section className="help-section">
						<header>
							<p>
								To Play With Past Years
								<span className="help-new">
									v1.9.0
								</span>
							</p>
						</header>
						<p>
							Load up a past season with <b>FIXTURE</b>, tick <b>Allow tipping of past games</b> and select a year. You
							can use the <b>ACTUAL</b> button on a single game to fill in the actual historical result, or do the whole
							season all at once with <b>RESET</b> → <b>RESET TO ACTUAL</b>. You might then want to review and tweak individual
							games with the <b>FIXTURE</b> button.
						</p>
					</section>
					<section className="help-section">
						<header>
							<p>About Squiggle AFL Ladder Predictor</p>
						</header>
						<p>
							Hi, I&apos;m Max. I made this for fun. More about me: <a target="_new" href="https://maxbarry.com">MaxBarry.com</a>
						</p>
						<p className="pcenter">
							<a target="_new" className="twitter-follow-button" title="Follow @SquiggleAFL on Twitter" href="https://twitter.com/intent/follow?original_referer=https%3A%2F%2Fsquiggle.com.au%2F&amp;ref_src=twsrc%5Etfw&amp;region=follow_link&amp;screen_name=SquiggleAFL&amp;tw_p=followbutton"><i></i><span>Follow @SquiggleAFL</span></a>
							<a target="_new" className="twitter-follow-button" title="Follow @MaxBarry on Twitter" href="https://twitter.com/intent/follow?original_referer=https%3A%2F%2Fsquiggle.com.au%2F&amp;ref_src=twsrc%5Etfw&amp;region=follow_link&amp;screen_name=MaxBarry&amp;tw_p=followbutton"><i></i><span>Follow @MaxBarry</span></a>

						</p>
					</section>
					<section className="help-section">
						<header>
							<p>How AutoTip Works</p>
						</header>
					<p>
							AutoTip is based on the various
							on <a target="_new" href="https://squiggle.com.au">Squiggle-supported football models</a>.
						</p>
						<p>
							But to generate a realistic ladder, you can&apos;t simply tip favourites, because
							favourites don&apos;t always win. A team that&apos;s 60% likely to win each game
							should be about 6-4 after ten games, not 10-0.
						</p>
						<p>
							So AutoTip runs thousands of simulations, with teams that are 60% likely to win a match
							only winning it about 60% of the time, and then selects the single sim that&apos;s most
							similar to all the others &mdash; a &ldquo;typical&rdquo; sim.
						</p>
						<p>
							This means the AutoTip result will have teams close to their most likely finishing positions,
							and with about the right number of upsets &mdash; but which particular games the upsets
							will be will change each time you run it. (If we could reliably predict which games would be upsets,
							they wouldn&apos;t be upsets.)
						</p>
						<p>
							AutoTip runs on your device and tries to be quick, so it only
							generates 5,000 simulations, which leaves quite a bit of room for random variation.
							In practice, serious football models usually run 25k-100k sims.
						</p>

					</section>

					<p className="pcenter">
						<button
							className="sbox command"
							onClick={this.closeModal}
						>
							Close
						</button>
					</p>

					<p>
						Version: {version}
					</p>

				</ReactModal>
			</div>
		);
	}
}

export default HelpText;
