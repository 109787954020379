import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { THIS_YEAR } from './DataImport.js';

const finalsFormats = {

	'AFL8': {
		from: 2000,
		name: 'AFL Final Eight',
		link: 'https://en.wikipedia.org/wiki/AFL_final_eight_system',
		weeks: [

			// Week 1
			{
				'QF1': {
					order: 1,
					hteam: props => props.ladder[0].id,
					ateam: props => props.ladder[3].id,
				},
				'QF2': {
					order: 4,
					hteam: props => props.ladder[1].id,
					ateam: props => props.ladder[2].id,
				},
				'EF1': {
					order: 2,
					hteam: props => props.ladder[4].id,
					ateam: props => props.ladder[7].id,
				},
				'EF2': {
					order: 3,
					hteam: props => props.ladder[5].id,
					ateam: props => props.ladder[6].id,
				},
			},

			// Week 2
			{
				'SF1': {
					order: 1,
					hteam: props => props.finals.QF1?.loser,
					ateam: props => props.finals.EF1?.winner,
				},
				'SF2': {
					order: 2,
					hteam: props => props.finals.QF2?.loser,
					ateam: props => props.finals.EF2?.winner,
				},
			},

			// Week 3
			{
				'PF1': {
					order: 1,
					hteam: props => props.finals.QF1?.winner,
					ateam: props => props.finals.SF2?.winner,
				},
				'PF2': {
					order: 2,
					hteam: props => props.finals.QF2?.winner,
					ateam: props => props.finals.SF1?.winner,
				},
			},

			// Week 4
			{
				'GF': {
					order: 1,
					hteam: props => props.finals.PF1?.winner,
					ateam: props => props.finals.PF2?.winner,
				},
			},

		],
	},

	'McIntyre8': {
		from: 1994,
		to: 1999,
		name: 'McIntyre Final Eight',
		link: 'https://en.wikipedia.org/wiki/McIntyre_Final_Eight_System',
		weeks: [

			// Week 1
			{
				'QF1': {
					order: 1,
					hteam: props => props.ladder[0].id,
					ateam: props => props.ladder[7].id,
				},
				'QF2': {
					order: 2,
					hteam: props => props.ladder[1].id,
					ateam: props => props.ladder[6].id,
				},
				'QF3': {
					order: 3,
					hteam: props => props.ladder[2].id,
					ateam: props => props.ladder[5].id,
				},
				'QF4': {
					order: 4,
					hteam: props => props.ladder[3].id,
					ateam: props => props.ladder[4].id,
				},
			},

			// Week 2
			{
				'SF1': {
					order: 1,
					hteam: props => mcIntyre(props, 'QF', 'winner', 4),
					ateam: props => mcIntyre(props, 'QF', 'loser', 2),
				},
				'SF2': {
					order: 2,
					hteam: props => mcIntyre(props, 'QF', 'winner', 3),
					ateam: props => mcIntyre(props, 'QF', 'loser', 1),
				},
			},

			// Week 3
			{
				'PF1': {
					order: 1,
					hteam: props => mcIntyre(props, 'QF', 'winner', 1),
					ateam: props => props.finals.SF1?.winner,
				},
				'PF2': {
					order: 2,
					hteam: props => mcIntyre(props, 'QF', 'winner', 2),
					ateam: props => props.finals.SF2?.winner,
				},
			},

			// Week 4
			{
				'GF': {
					order: 1,
					hteam: props => props.finals.PF1?.winner,
					ateam: props => props.finals.PF2?.winner,
				},
			},
		],
	},

	'McIntyre6v2': {
		from: 1992,
		to: 1993,
		name: 'Second McIntyre Final Six',
		link: 'https://en.wikipedia.org/wiki/McIntyre_System#Second_McIntyre_final_six_system',
		weeks: [

			// Week 1
			{
				'QF': {
					order: 1,
					hteam: props => props.ladder[0].id,
					ateam: props => props.ladder[1].id,
				},
				'EF1': {
					order: 2,
					hteam: props => props.ladder[2].id,
					ateam: props => props.ladder[5].id,
				},
				'EF2': {
					order: 3,
					hteam: props => props.ladder[3].id,
					ateam: props => props.ladder[4].id,
				},
			},

			// Week 2
			{
				'SF1': {
					order: 1,
					hteam: props => props.finals.QF?.loser,
					ateam: props => mcIntyre(props, 'EF', 'winner', 2),
				},
				'SF2': {
					order: 2,
					hteam: props => props.finals.QF?.winner,
					ateam: props => mcIntyre(props, 'EF', 'winner', 1),
				},
			},

			// Week 3
			{
				'PF': {
					order: 1,
					hteam: props => props.finals.SF2?.loser,
					ateam: props => props.finals.SF1?.winner,
				},
			},

			// Week 4
			{
				'GF': {
					order: 1,
					hteam: props => props.finals.SF2?.winner,
					ateam: props => props.finals.PF?.winner,
				},
			},
		],
	},

	'McIntyre6v1': {
		from: 1991,
		to: 1991,
		name: 'First McIntyre Final Six',
		link: 'https://en.wikipedia.org/wiki/McIntyre_System#First_McIntyre_final_six_system',
		weeks: [

			// Week 1
			{
				'QF': {
					order: 1,
					hteam: props => props.ladder[0].id,
					ateam: props => props.ladder[1].id,
				},
				'EF1': {
					order: 2,
					hteam: props => props.ladder[4].id,
					ateam: props => props.ladder[5].id,
				},
				'EF2': {
					order: 3,
					hteam: props => props.ladder[2].id,
					ateam: props => props.ladder[3].id,
				},
			},

			// Week 2
			{
				'SF1': {
					order: 1,
					hteam: props => props.finals.QF?.loser,
					ateam: props => props.finals.EF1?.winner,
				},
				'SF2': {
					order: 2,
					hteam: props => props.finals.QF?.winner,
					ateam: props => props.finals.EF2?.winner,
				},
			},

			// Week 3
			{
				'PF': {
					order: 1,
					hteam: props => props.finals.SF2?.loser,
					ateam: props => props.finals.SF1?.winner,
				},
			},

			// Week 4
			{
				'GF': {
					order: 1,
					hteam: props => props.finals.SF2?.winner,
					ateam: props => props.finals.PF?.winner,
				},
			},
		],
	},

	'McIntyre5': {
		from: 1972,
		to: 1990,
		name: 'McIntyre Final Five',
		link: 'https://en.wikipedia.org/wiki/McIntyre_System#McIntyre_final_five_system',
		weeks: [

			// Week 1
			{
				'QF': {
					order: 1,
					hteam: props => props.ladder[1].id,
					ateam: props => props.ladder[2].id,
				},
				'EF': {
					order: 2,
					hteam: props => props.ladder[3].id,
					ateam: props => props.ladder[4].id,
				},
			},

			// Week 2
			{
				'SF1': {
					order: 1,
					hteam: props => props.finals.QF?.loser,
					ateam: props => props.finals.EF?.winner,
				},
				'SF2': {
					order: 2,
					hteam: props => props.ladder[0].id,
					ateam: props => props.finals.QF?.winner,
				},
			},

			// Week 3
			{
				'PF': {
					order: 1,
					hteam: props => props.finals.SF2?.loser,
					ateam: props => props.finals.SF1?.winner,
				},
			},

			// Week 4
			{
				'GF': {
					order: 1,
					hteam: props => props.finals.SF2?.winner,
					ateam: props => props.finals.PF?.winner,
				},
			},
		],
	},

	'PageMcIntyre4': {
		from: 1931,
		to: 1971,
		name: 'Page–McIntyre',
		link: 'https://en.wikipedia.org/wiki/McIntyre_System#Page%E2%80%93McIntyre_system',
		weeks: [

			// Week 1
			{
				'SF1': {
					order: 1,
					hteam: props => props.ladder[2].id,
					ateam: props => props.ladder[3].id,
				},
			},

			// Week 2
			{
				'SF2': {
					order: 2,
					hteam: props => props.ladder[0].id,
					ateam: props => props.ladder[1].id,
				},
			},

			// Week 3
			{
				'PF': {
					order: 1,
					hteam: props => props.finals.SF2?.loser,
					ateam: props => props.finals.SF1?.winner,
				},
			},

			// Week 4
			{
				'GF': {
					order: 1,
					hteam: props => props.finals.SF2?.winner,
					ateam: props => props.finals.PF?.winner,
				},
			},
		],
	},

	'Argus4RR': {
		from: 1924,
		to: 1924,
		name: 'Argus Round Robin',
		link: 'https://en.wikipedia.org/wiki/Argus_finals_system#Round-robin_Argus_system',
		weeks: [ ],
	},

	'Argus4v3': {
		from: 1908,
		to: 1930,
		excl: 1924,
		name: 'Second Amended Argus',
		link: 'https://en.wikipedia.org/wiki/Argus_finals_system#Second_amended_Argus_system',
		weeks: [ ],
	},

	'Argus4v2': {
		from: 1902,
		to: 1907,
		name: 'First Amended Argus',
		link: 'https://en.wikipedia.org/wiki/Argus_finals_system#First_amended_Argus_system',
		weeks: [ ],
	},

	'Argus4v1': {
		from: 1901,
		to: 1901,
		name: 'First Argus',
		link: 'https://en.wikipedia.org/wiki/Argus_finals_system#First_Argus_system',
		weeks: [ ],
	},

	'FullParticipation8': {
		from: 1898,
		to: 1900,
		name: 'Full Participation Round Robin',
		link: 'https://en.wikipedia.org/wiki/1898_VFL_finals_series#Finals_system',
		weeks: [ ],
	},

	'RoundRobin': {
		from: 1897,
		to: 1897,
		name: 'Round Robin',
		link: 'https://en.wikipedia.org/wiki/AFL_finals_series',
		weeks: [ ],
	},
};

class Finals extends Component {

	static propTypes = {
		finalsResults: PropTypes.object,
		loadTime: PropTypes.number,
	}

	constructor(props) {
		super(props);
		this.state = {
			finals: { },
		};
		this.onClick = this.onClick.bind(this);
		this.hTeam = this.hTeam.bind(this);
		this.aTeam = this.aTeam.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (!nextProps.showFinals) {
			return null;
		}

		return verifyFinalsGames(nextProps, prevState.finals);
	}

	onClick(data) {
		const finals = Object.assign( {}, this.state.finals);
		finals[data.final.id].winner = data.winner.id;
		finals[data.final.id].loser = data.loser.id;
		finals[data.final.id].type = data.type;

		// console.log('click!', data, 'setting winner to', data.winner.id, finals[data.final.id])

		verifyFinalsGames(this.props, finals);

		this.setState({
			finals: finals,
		});
	}

	//
	// When we change year, restore any saved finals
	//
	componentDidUpdate(prevProps) {

		if (!Object.keys(this.state.finals).length || (prevProps.loadTime && prevProps.loadTime !== this.props.loadTime)) {

			// console.log('loadTime changed from', prevProps.loadTime, 'to', this.props.loadTime);

			const newState = {
				finals: { },
			}

			const { numRounds, fixtureYear } = this.props

			const finalsFormat = queryFinalsFormat(fixtureYear);

			if (finalsFormat.length) {

				finalsFormat.forEach((finalsGames, index) => {
					const finalsWeek = index + 1
					Object.keys(finalsGames).forEach(id => {
						newState.finals[id] = {
							id,
							round: numRounds + finalsWeek,
						}
					})
				})

				if (this.props.savedFinals) {
					newState.finals = Object.assign(newState.finals, this.props.savedFinals);
				}

				this.setState(newState)

			}
		}
	}

	componentDidMount() {
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(null);
	}

	getFinals() {
		if (!this.props.showFinals) {
			return null;
		}
		return this.state.finals;
	}

	finalsTeam(finalId, winner) {
		const final = this.state.finals[finalId];
		if (final && final[winner]) {
			return this.props.teams[final[winner]];
		}
		return null;
	}

	hTeam(finalId) {
		return this.props.teams[this.state.finals[finalId]?.hteam]
	}

	aTeam(finalId) {
		return this.props.teams[this.state.finals[finalId]?.ateam]
	}

	render() {

		if (!this.props.showFinals || !Object.keys(this.state.finals).length) {
			return null;
		}

		const finals = queryFinals(this.props.fixtureYear);

		let finalsSystemContent = null
		if (finals.name !== 'AFL Final Eight') {
			const duration = finals.from === finals.to ? finals.from : `${finals.from} - ${finals.to}`
			finalsSystemContent = (
				<div className="finals-system">
					<a href={finals.link} target="_blank" rel="noreferrer noopener">
						{finals.name} System ({duration})
					</a>
				</div>
			)
		}
		return (
			<div id="finals">
				{finalsSystemContent}
				{
					finals.weeks.map((finalsGames, index) => (
						<div key={`finalsweek-${index + 1}`} className={`finalsweek finalsweek-${index + 1}`}>
							{

								Object.keys(finalsGames).sort((a, b) => finalsGames[a].order - finalsGames[b].order).map(id => (
									<FinalsBox key={id} final={this.state.finals[id]} onClick={this.onClick} home={this.hTeam(id)} away={this.aTeam(id)} />
								))
							}
						</div>
					))
				}
			</div>
		);

	}
}

Finals.propTypes = {
	showFinals: PropTypes.bool,
	ladder: PropTypes.array,
	finals: PropTypes.object,
	teams: PropTypes.object,
	savedFinals: PropTypes.object,
	onRef: PropTypes.func,
	allowPastTips: PropTypes.bool,
	numRounds: PropTypes.number,
	fixtureYear: PropTypes.string,
};

class FinalsBox extends Component {
	render() {

		const { final, home, away, onClick } = this.props;

		if (!final)
			return null;

		return (
			<div className="finalscontainer">
				<div className="finalslabel">
					{final.id}
				</div>
				<div className="finalsbox">
					<FinalsBoxTeam final={final} onClick={onClick} type="home" team={home} opponent={away} />
					<FinalsBoxTeam final={final} onClick={onClick} type="away" team={away} opponent={home} />
				</div>
			</div>
		);
	}
}

FinalsBox.propTypes = {
	final: PropTypes.object,
	home: PropTypes.object,
	away: PropTypes.object,
	onClick: PropTypes.func,
};

class FinalsBoxTeam extends Component {

	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		if (this.props.team && this.props.opponent) {
			return this.props.onClick({
				final: this.props.final,
				winner: this.props.team,
				loser: this.props.opponent,
				type: this.props.type,
			});
		}
	}

	render() {
		const className = 'finalsboxteam' + (this.props.team && this.props.final.winner === this.props.team.id ? ' winner' : '');
		const img = (this.props.team ? ( <img src={this.props.team.logo} alt={this.props.team.name} /> ) : '');
		return (
			<div className={className}
				onClick={this.onClick}
			>
				{img}
			</div>
		);
	}
}

FinalsBoxTeam.propTypes = {
	final: PropTypes.object.isRequired,
	team: PropTypes.object,
	opponent: PropTypes.object,
	onClick: PropTypes.func.isRequired,
	type: PropTypes.string,
};

//
// Make sure that all previously-set winners and losers are actually participants in the game.
//
function verifyFinalsGames(props, finals, infiniteLoopProtection) {

	// console.log('verifyFinalsGames', infiniteLoopProtection);

	let needsUpdate = false;

	if (!Object.keys(finals).length) {
		return null;
	}

	const { ladder, finalsResults, allowPastTips, fixtureYear } = props

	const finalsFormat = queryFinalsFormat(fixtureYear);

	//
	// Calculate which teams are playing in which final.
	//

	finalsFormat.forEach(finalsGames => {

		Object.keys(finalsGames).forEach(id => {

			const final = finals[id];

			if (!final) {
				// we're probably changing finals formats
				return;
			}

			const hteam = finalsGames[id].hteam({ ladder, finals });
			const ateam = finalsGames[id].ateam({ ladder, finals });

			if (hteam !== final.hteam) {
				final.hteam = hteam;
				needsUpdate = true;
			}

			if (ateam !== final.ateam) {
				final.ateam = ateam;
				needsUpdate = true;
			}

		});

	});

	//
	// Insert winners & losers from real-world results.
	//

	// console.log('finalsResults', finalsResults);

	finalsFormat.forEach(finalsGames => {

			if (Object.keys(finalsResults).length) {

				Object.keys(finalsGames).forEach(id => {

					const final = finals[id];

					// console.log('final', id, final);

					if (!final)
						return;

					//
					// If we have a real-world result, insert the winner/loser - we may overwrite it below.
					//
					// Each finals game, we have to cycle through all our `finalsResults` looking for
					// one that matches round, hteam, and ateam.
					//
					Object.values(finalsResults).forEach(finalResult => {
						if (final.round === finalResult.round && finalResult.winner && ((final.ateam === finalResult.ateamid && final.hteam === finalResult.hteamid) || (final.ateam === finalResult.hteamid && final.hteam === finalResult.ateamid))) {
							if (!allowPastTips || !final.winner) {
								// console.log("Setting final.winner in", id, "to", finalResult.winnerteamid, finalResult)
								final.winner = finalResult.winnerteamid;
								final.loser = (final.winner === final.hteam ? final.ateam : final.hteam);
								needsUpdate = true;
							}
						}
					})
				})
		}
	});

	finalsFormat.forEach(finalsGames => {

		//
		// Delete any winners & losers that don't make sense (because the user changed
		// a tip, most likely).
		//
		Object.keys(finalsGames).forEach(id => {

			const final = finals[id];

			if (!final)
				return;

			for (let w of [ 'winner', 'loser' ]) {
				if (final[w]) {
					if (final[w] !== finalsGames[id].hteam({ ladder, finals }) && final[w] !== finalsGames[id].ateam({ ladder, finals })) {
						console.log('Delete', w, final[w], 'in game', id, 'beause it is not competing');
						final.winner = null;
						final.loser = null;
						needsUpdate = true;
					}
				}
			}

		});

	});

	if (!needsUpdate) {
		return null;
	}

	// We changed something, so we need to loop again
	if (!infiniteLoopProtection || infiniteLoopProtection < 20) {
		verifyFinalsGames(props, finals, 1 + (infiniteLoopProtection || 0));
	}

	return { finals };
}


function queryFinals(yr) {

	const year = parseInt(yr || THIS_YEAR, 10);

	const formatId = Object.keys(finalsFormats).find(id => {
		const obj = finalsFormats[id];

		if (
			year >= obj.from
			&&
			(!obj.to || year <= obj.to)
			&&
			(!obj.excl || year !== obj.excl)
		) {
			return true;
		}

		return false;
	});

	return finalsFormats[formatId];
}

function queryFinalsFormat(year) {
	return queryFinals(year).weeks;
}

// Rank winners & losers from QF games
function mcIntyre(props, type, w, rank) {

	const { ladder, finals } = props;

	const ranks = ladder.reduce((map, obj) => {
		map[obj.id] = obj.rank;
		return map;
	}, { });

	const list = [ ];

	Object.keys(finals).forEach(id => {
		if (id.substring(0, 2) === type) {
			const teamid = finals[id][w];
			list[ranks[teamid]] = teamid;
		}
	});

	const validTeams = list.filter(v => v !== undefined);
	const result = validTeams[rank - 1];

	// console.log('ranks', ranks, list, list, 'validTeams', validTeams, w, rank, result);

	return result;
}

export default Finals;
