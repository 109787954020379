import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import ModalCloseBox from './ModalCloseBox';
import copy from 'copy-to-clipboard'; // now obsolete but keeping in list of dependencies due to service worker caching
import copyToClipboard from './CopyToClipboard';
import FlipMove from 'react-flip-move';
import Finals from './Finals';

import logo from './logo.png';
import './tick.css';

class Ladder extends Component {
	getFinals = () => {
		return this.child.getFinals();
	}

	constructor(props) {
		super(props);
		this.state = {
			ladderTeams: null,
			someFewer: false,
		};
	}

	static getDerivedStateFromProps(nextProps) {

		if (!nextProps.teams) {
			return null;
		}

		return computeLadder(nextProps.teams, nextProps.games, nextProps.results, nextProps.tips, nextProps.complete);
	}

	render() {

		if (!this.state.ladderTeams) {
			return null;
		}

        const showFinals = Object.keys(this.props.games).length === Object.keys(this.props.tips).length;

        const numFinalsTeams = queryNumFinalsTeams(parseInt(this.props.fixtureYear, 10) || 0);

		const numRounds = Object.values(this.props.games).reduce((maxRound, game) => Math.max(maxRound, game.round), 0)

		const { hteamid, ateamid } = this.props.game || { }

		return (
			<div id="LP-ladder">
				<div id="liveladder">
					<div id="liveladder-thead" className="liveladder-row">
						<div><img src={logo} alt="" className="logo" /></div><div></div><div></div><div>W</div><div>L</div><div>D</div><div>%</div><div></div>
					</div>

					<FlipMove
						duration={750}
						easing="ease-out"
						id="liveladder-tbody"
					>
						{
							this.state.ladderTeams.map(t => {
								const highlight = t.id === hteamid || t.id === ateamid
								return (
									<LadderRow t={t} key={t.id} top8={t.rank <= numFinalsTeams} highlight={highlight} />
								)
							})
						}
					</FlipMove>

				</div>

				<div id="laddertext">
					{this.state.someFewer && '+ This team has played fewer games.'}
				</div>

				<Finals
					showFinals={showFinals}
					fixtureYear={this.props.fixtureYear}
					ladder={this.state.ladderTeams}
					finalsResults={this.props.finals}
					teams={this.props.teams}
					numRounds={numRounds}
					savedFinals={this.props.savedFinals}
					loadTime={this.props.loadTime}
					allowPastTips={this.props.allowPastTips}
					onRef={ref => (this.child = ref)}
				/>

				<LadderCopy
					ladder={this.state.ladderTeams}
					teams={this.props.teams}
					saveUserTips={this.props.saveUserTips}
					getFinals={this.getFinals} />

			</div>
		);
	}
}

Ladder.propTypes = {
	teams: PropTypes.object,
	results: PropTypes.object,
	tips: PropTypes.object,
	finals: PropTypes.object,
	savedFinals: PropTypes.object,
	getFinals: PropTypes.func,
	allowPastTips: PropTypes.bool,
	games: PropTypes.object,
	game: PropTypes.object,
	saveUserTips: PropTypes.func,
	loadTime: PropTypes.any,
	complete: PropTypes.object,
	fixtureYear: PropTypes.string,
};

class LadderRow extends Component {
	render() {

		const { top8, highlight } = this.props

		const myClass = [ 'liveladder-row' ]
		if (top8) {
			myClass.push('top8')
		}
		if (highlight) {
			myClass.push('highlight')
		}

		return (
			<div className={myClass.join(' ')}>
				<div>{this.props.t.rank}</div>
				<div><img src={this.props.t.logo} alt="" className="ladderteamlogo" /></div>
				<div>{this.props.t.name}</div>
				<div>{this.props.t.wins}</div>
				<div>{this.props.t.losses}</div>
				<div>{this.props.t.draws}</div>
				<div>{Number(this.props.t.perc).toFixed(1)}</div>
				<div>{this.props.t.playedMost ? '' : '+'}</div>
			</div>
		);
	}
}

LadderRow.propTypes = {
	t: PropTypes.object,
	top8: PropTypes.bool,
	highlight: PropTypes.bool,
};

function queryNumFinalsTeams(year) {

    if (!year || year < 1897) {
        return 8;
    }

    const numTeams = [
        { year: 1994, n: 8 },
        { year: 1991, n: 6 },
        { year: 1972, n: 5 },
        { year: 1900, n: 4 },
        { year: 1897, n: 0 },
    ];

    const { n } = numTeams.find(obj => year >= obj.year);

    return n;
}

//
// Note that AutoTip.js doesn't bother sending 'complete'
// data, since it doesn't care that much about precise
// tiebreaking.
//
export function computeLadder(teams, games, results, tips, complete) {

	//
	// Iterate through tips to create a 'teams'-like object that contains
	// wins, losses, pts for, etc
	//
	// 1. Copy 'results', which contains starting values.
	//
	const r2 = { };
	for (let id in teams) {
		r2[id] = Object.assign( {}, results[id]);
	}

	//
	// 2. Add tipped wins & losses
	//
	for (let tip of Object.values(tips)) {
		const g = games[tip.gameId];
		const winner = tip.winner;

		if (winner) {
			const loser = tip.winner === g.hteamid ? g.ateamid : g.hteamid;
			r2[winner].wins++;
			r2[loser].losses++;
		} else {
			r2[g.hteamid].draws++;
			r2[g.ateamid].draws++;
		}

		r2[g.hteamid].ptsFor += tip.hPts;
		r2[g.hteamid].ptsAgt += tip.aPts;
		r2[g.ateamid].ptsFor += tip.aPts;
		r2[g.ateamid].ptsAgt += tip.hPts;
	}

	//
	// 3. Merge with 'teams'
	//
	let mostGamesPlayed = 0;
	const ladderTeams = Object.values(teams).map(t => {
		let r = r2[t.id];
		t.wins = r.wins;
		t.losses = r.losses;
		t.draws = r.draws;
		t.played = t.wins + t.losses + t.draws;	// not used yet (too many numbers makes it confusing!), but maybe in future
		if (t.played > mostGamesPlayed) {
			mostGamesPlayed = t.played;
		}
		t.pts = (r.wins * 4) + (r.draws * 2);
		t.perc = r.ptsAgt ? 100 * r.ptsFor / r.ptsAgt : 100;
		t.ptsFor = r.ptsFor
		t.ptsAgt = r.ptsAgt
		return t;
	});

	let someHavePlayedFewer = false;
	for (let t of ladderTeams) {
		t.playedMost = t.played >= mostGamesPlayed;
		if (!t.playedMost) {
			someHavePlayedFewer = true;
		}
	}

	//
	// 4. Now sort it.
	//
	//
	// AFL rules (as at August 2022):
	// 1. Premiership points
	// 2. Percentage
	// 3. Head-to-head record involving tied teams
	// 4. Percentage involving tied teams
	// 5. Random drawn lots
	//

	const ties = { };

	ladderTeams.sort((a, b) => {

		const ptsDiff = b.pts - a.pts;
		if (ptsDiff !== 0) {
			return ptsDiff;
		}

		const aPerc = a.ptsAgt ? (a.ptsFor / a.ptsAgt) : (a.ptsFor ? Infinity : 0);
		const bPerc = b.ptsAgt ? (b.ptsFor / b.ptsAgt) : (b.ptsFor ? Infinity : 0);

		const percDiff = bPerc - aPerc;

		if (percDiff) {
			return percDiff;
		}

		if (a.played) {
			//
			// If we reach here, we need to figure out head-to-head results,
			// possibly involving 3+ teams. For now, just flag the teams we need
			// to tiebreak.
			//

			if (!ties[a.pts]) {
				ties[a.pts] = { };
			}
			ties[a.pts][a.id] = true;
			ties[a.pts][b.id] = true;
		}

		return a.id - b.id;	// In the case of ties, we'll override this in a moment
	})

	// console.log('ties', ties);

	//
	// If we have any ties, resolve that now.
	//
	// https://www.theage.com.au/sport/afl/a-coin-toss-how-afl-tie-breakers-work-on-the-final-round-ladder-20190820-p52iyx.html
	//
	Object.keys(ties).forEach(pts => {
		const teamIds = Object.keys(ties[pts]).map(id => Number(id))

		const allGames = { ...games, ...complete }

		const relevantGames = Object.values(allGames).filter(game => {
			const { hteamid, ateamid } = game
			return teamIds.includes(hteamid) && teamIds.includes(ateamid)
		});

		const head2head = { };
		teamIds.forEach(id => {
			head2head[id] = { pts: 0, for: 0, against: 0 };
		})

		// console.log('relevantGames', relevantGames)

		relevantGames.forEach(game => {
			const { hteamid, ateamid } = game;
			let winner;
			let hscore;
			let ascore;
			if (complete && complete[game.id]) {
				winner = complete[game.id].winnerteamid;
				hscore = complete[game.id].hscore;
				ascore = complete[game.id].ascore;
			} else if (tips[game.id]) {
				winner = tips[game.id].winner;
				hscore = tips[game.id].hPts;
				ascore = tips[game.id].aPts;
			} else {
				// Game is probably a past real-life game but we're tipping
				// past games.
				// console.error("Can't find game id", game.id, "in complete", complete, "nor tips", tips);
				return;
			}

			if (winner) {
				head2head[winner].pts += 4;
			} else {
				head2head[hteamid].pts += 2;
				head2head[ateamid].pts += 2;
			}

			head2head[hteamid].for += hscore;
			head2head[ateamid].for += ascore;
			head2head[hteamid].against += ascore;
			head2head[ateamid].against += hscore;
		})

		// console.log("head2head results", head2head)

		const sortedTeamIds = teamIds.sort((a, b) => {

			const head2headPtsDiff = head2head[b].pts - head2head[a].pts
			if (head2headPtsDiff) {
				// console.log("Tiebreaker on head-to-head premiership pts")
				return head2headPtsDiff
			}

			Object.keys(head2head).forEach(teamId => {
				let perc = 0
				if (head2head[teamId].against) {
					perc = 100 * head2head[teamId].for / head2head[teamId].against
				}
				head2head[teamId].perc = perc
			})

			const head2headPercDiff = head2head[b].perc - head2head[a].perc
			if (head2headPercDiff) {
				// console.log("Tiebreaker on head-to-head percentage")
				return head2headPercDiff
			}

			// console.log("Tiebreaker on random lots")
			return a - b
		});

		// console.log("Tie sorting:", sortedTeamIds)

		ladderTeams.forEach(team => {
			if (sortedTeamIds.includes(team.id)) {
				team.tiebreak = sortedTeamIds
			}
		})

		ladderTeams.sort((a, b) => {
			if (a.tiebreak && a.tiebreak.includes(b.id)) {
				return a.tiebreak.findIndex(value => value === a.id) - a.tiebreak.findIndex(value => value === b.id)
			}
			return 0
		})
	})

	// Expel Essendon from finals at end of 2013 season
	if (Object.keys(tips).length === Object.keys(games).length && Object.keys(games).length && Object.values(games)[0].year === 2013) {
		const essendonIndex = ladderTeams.findIndex(obj => obj.id === 5);
		if (essendonIndex < 8) {
			console.log("Expelling Essendon from finals");
			const essendon = ladderTeams[essendonIndex];
			ladderTeams.splice(essendonIndex, 1);
			ladderTeams.splice(8, 0, essendon);
		}
	}

	//
	// 5. Add rank.
	//
	let rank = 1;
	for (let t of ladderTeams) {
		t.rank = rank;
		rank++;
	}

	return {
		ladderTeams: ladderTeams,
		someFewer: someHavePlayedFewer,
	};
}

class LadderCopy extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			copyFormat: 'Text',
			showTick: false,
		};

		this.onClick = this.onClick.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	onClick(copyFormat) {
		let output;
		if (typeof copyFormat !== 'string') {
			copyFormat = this.state.copyFormat;
		}

		if (copyFormat === 'Reddit') {
			output =
				" | |W|L|D|%\n" +
				":---------:|:--------|--------:|---------:|---------:|---------:\n" +
				this.props.ladder.map((t) =>
					String(t.rank) + "." +
					'|' +
					(t.rank <= 8 ? '**' : '') +
					String(t.name) +
					(t.rank <= 8 ? '**' : '') +
					'|' +
					String(t.wins) +
					'|' +
					String(t.losses) +
					'|' +
					String(t.draws) +
					'|' +
					String(Number(t.perc).toFixed(1)) + "%" +
					(t.playedMost ? "" : " +")
				).join("\n") +
				"\n";
		} else if (copyFormat === 'BigFooty') {
			output =
				"[TABLE]" +
				"[TR][TD][/TD][TD][/TD][TD][b]W[/b][/TD][TD][b]L[/b][/TD][TD][b]D[/b][/TD][TD][b]%[/b][/TD][/TR]\n" +
				this.props.ladder.map((t) =>
					"[TR][TD]" +
					String(t.rank) + "." +
					'[/TD][TD]' +
					(t.rank <= 8 ? '[b]' : '') +
					String(t.name) +
					(t.rank <= 8 ? '[/b]' : '') +
					'[/TD][TD]' +
					String(t.wins) +
					'[/TD][TD]' +
					String(t.losses) +
					'[/TD][TD]' +
					String(t.draws) +
					'[/TD][TD]' +
					String(Number(t.perc).toFixed(1)) + "%" +
					(t.playedMost ? "" : " +") +
					"[/TD][/TR]"
				).join("\n") +
				"[/TABLE]" +
				"\n";
		} else {
			copyFormat = 'Text';
			output =
				"                        W  L  D\n" +
				this.props.ladder.map((t) =>
					String(t.rank).padStart(2) +
					". " +
					String(t.name).padEnd(18) +
					" " +
					String(t.wins).padStart(2) +
					" " +
					String(t.losses).padStart(2) +
					" " +
					String(t.draws).padStart(2) +
					" " +
					String(Number(t.perc).toFixed(1)).padStart(6) + "%" +
					(t.playedMost ? "" : " +")
				).join("\n") +
				"\n";
		}

		const finals = this.props.getFinals();

		if (finals) {
			let winnerFormatPre, winnerFormatPost, sectionStart, lineStart;

			if (copyFormat === 'Reddit') {
				winnerFormatPre = winnerFormatPost = '**';
				sectionStart = '';
				lineStart = "\n";
			} else if (copyFormat === 'BigFooty') {
				winnerFormatPre = '[b]';
				winnerFormatPost = '[/b]';
				sectionStart = '';
				lineStart = '';
			} else {
				winnerFormatPre = winnerFormatPost = '*';
				sectionStart = "\n";
				lineStart = '';
			}

			const lineBreaksAfter = [ 'EF2', 'SF2', 'PF2' ];

			output +=
				sectionStart +
				Object.keys(finals).map((id) =>
					{
						const f = finals[id];
						if (!f.winner) {
							return null;
						}
						const winnerName = winnerFormatPre + String(this.props.teams[f.winner].name).toUpperCase() + winnerFormatPost;
						const loserName = String(this.props.teams[f.loser].name);
						const finalName = lineStart + String(f.id).padStart(3);
						const finalLineBreak = lineBreaksAfter.includes(String(f.id)) ? "\n\n" : "\n";
						if (f.type === 'home') {
							return finalName + ": " + winnerName + ' v ' + loserName + finalLineBreak;
						} else {
							return finalName + ": " + loserName + ' v ' + winnerName + finalLineBreak;
						}
					}
				).join('') +
				"\n";
		}

		copy(output);	// duplicate for now because I'm not sure this is working with caching. TODO: remove this line
		copyToClipboard(output, { debug: true });

		this.setState({
			modalIsOpen: true,
			copyFormat: copyFormat,
			showTick: false,
		});

		this.props.saveUserTips(finals);

		setTimeout(() => this.setState({ showTick: true }), 120);
	}

	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
	}

	render() {
		const myFormats = [ "Text", "Reddit", "BigFooty" ];

		return (
			<section id="ladder-copy">
				<button
					className="sbox command"
					onClick={this.onClick}
				>
					Save &amp; Copy
				</button>
				<ReactModal
					isOpen={this.state.modalIsOpen}
					overlayClassName="ModalOverlay"
					className="ModalContent ModalContentSmall"
					contentLabel="Ladder Copied"
					shouldCloseOnOverlayClick={true}
					shouldCloseOnEsc={true}
					onRequestClose={this.closeModal}
				>
					<ModalCloseBox closeModal={this.closeModal} />
					<h2>
						Ladder copied
					</h2>
					<p>
						Format: {this.state.copyFormat}
					</p>
					<Tick showTick={this.state.showTick} />
					<p className="sbox-small-row">
						{myFormats.map((copyFormat) =>
							<LadderCopyFormatButton
								key={copyFormat}
								onClick={this.onClick}
								copyFormatName={copyFormat}
								copyFormatClass={this.state.copyFormat === copyFormat ? 'selected' : ''}
							/>
						)}
					</p>
				</ReactModal>
			</section>

		);
	}
}

LadderCopy.propTypes = {
	ladder: PropTypes.array,
	teams: PropTypes.object,
	finals: PropTypes.object,
	getFinals: PropTypes.func,
	toggleShowFixture: PropTypes.bool,
	saveUserTips: PropTypes.func,
};

class Tick extends Component {
	render() {
		return (
			<div id="tickbox">
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" id="tick" className={this.props.showTick ? '' : 'hidden'} >
					<circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
					<polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
				</svg>
			</div>
		);
	}
}

Tick.propTypes = {
	showTick: PropTypes.bool,
};

class LadderCopyFormatButton extends Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		this.props.onClick(this.props.copyFormatName);
	}

	render() {
		return (
			<button
				className={"sbox sbox-small command " + this.props.copyFormatClass}
				onClick={this.onClick}
			>
				{this.props.copyFormatName}
			</button>
		);
	}
}

LadderCopyFormatButton.propTypes = {
	copyFormatClass: PropTypes.string,
	copyFormatName: PropTypes.string,
	onClick: PropTypes.func,
};


export default Ladder;
