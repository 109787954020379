import React from 'react';
import PropTypes from 'prop-types';

function ModalCloseBox(props) {
	return (
		<div className="closebox">
			<button
				className="sbox command"
				onClick={props.closeModal}
			>
				Close
			</button>
		</div>
	);
}

ModalCloseBox.propTypes = {
	closeModal: PropTypes.func.isRequired,
};

export default ModalCloseBox;
