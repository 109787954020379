//
// Forked from copy-to-clipboard 3.0.8 by sudodoki
//
// https://www.npmjs.com/package/copy-to-clipboard
//
// Modified because the default element of 'span' causes it to be interpreted
// as styled text in some cases (e.g. pasting on BigFooty.com: see
// https://www.bigfooty.com/forum/threads/pasting-in-bigfooty-strips-newlines.1199322).
//
// Wrapping it in 'pre' instead works better.
//

import deselectCurrent from 'toggle-selection';

export default function copyToClipboard(text, options) {

	var reselectPrevious, range, selection, mark, success = false;

	if (!options) { options = {}; }
	const debug = options.debug || false;

	debug && console.log("Copying!");

	try {
		reselectPrevious = deselectCurrent();

		range = document.createRange();
		selection = document.getSelection();

		mark = document.createElement('pre');
		mark.textContent = text;
		// reset user styles for span element
		mark.style.all = 'unset';
		// prevents scrolling to the end of the page
		mark.style.position = 'fixed';
		mark.style.top = 0;
		mark.style.clip = 'rect(0, 0, 0, 0)';
		// used to preserve spaces and line breaks
		mark.style.whiteSpace = 'pre';
		// do not inherit user-select (it may be `none`)
		mark.style.webkitUserSelect = 'text';
		mark.style.MozUserSelect = 'text';
		mark.style.msUserSelect = 'text';
		mark.style.userSelect = 'text';

		document.body.appendChild(mark);

		range.selectNode(mark);
		selection.addRange(range);

		var successful = document.execCommand('copy');
		if (!successful) {
			throw new Error('copy command was unsuccessful');
		}
		success = true;
	} catch (err) {
		debug && console.error('unable to copy using execCommand: ', err);
		debug && console.warn('trying IE specific stuff');
		try {
			window.clipboardData.setData('text', text);
			success = true;
		} catch (err) {
			debug && console.error('unable to copy using clipboardData: ', err);
		}
	} finally {
		if (selection) {
			if (typeof selection.removeRange === 'function') {
				selection.removeRange(range);
			} else {
				selection.removeAllRanges();
			}
		}

		if (mark) {
			document.body.removeChild(mark);
		}
		reselectPrevious();
	}

	return success;
}
